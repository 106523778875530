<template>
  <div class="assign-container">
    <div
      v-if="selectedUsers.length && search_key.length < 3"
      class="assigned-users"
    >
      <div class="subtitle">Assigned Users</div>
      <div
        class="member-row"
        v-for="(user, user_index) in selectedUsers"
        :key="user_index"
      >
        <div class="pic">
          <img
            :src="
              user.avatar
                ? imgURL + user.avatar
                : '/assets/images/assign-member.png'
            "
            alt=""
          />
        </div>
        <div class="name">
          {{ user.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
export default {
  props: {
    details: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeout: null,
      title: "Remote Support History",
      ticketId: "",
      priorityStatus: "",
      search_key: "",
      searchResult: [],
      selectedUsers: this.details.assigned_users,
      search_data_available: false,
      internalInstance: getCurrentInstance(),
      imgURL: process.env.VUE_APP_DO_SPACES,
    };
  },
  watch: {
    search_key() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.getData(1);
      }, 1000);
    },
  },
  methods: {
    onClickOutside() {
      this.search_key = "";
    },
  },
  created() {
    this.ticketId = this.$route.params.ticketid;
  },
};
</script>

<style scoped>
.search {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.headerPan {
  flex-direction: column;
}
.ticket-right .assign-container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ticket-right .assign-container .subtitle {
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0 0 5px 0;
  width: fit-content;
  color: var(--primary-color);
}
.assign-container .headerPan .dropdown-menu {
  width: 50%;
  position: unset;
}
.userSearch .searchPan {
  border-radius: 5px;
  width: 30%;
}
.userSearch .searchPan .form-control {
  background: #f2f5f8;
  padding: 0 30px;
  border: 1px solid transparent;
  transition: 0.2s;
  border-radius: 5px;
}
.userSearch .searchPan .form-control:focus {
  outline: none;
  border-radius: 5px;
  border: 1px solid #ced4da;
  box-shadow: unset;
}
.userSearch .searchPan .form-control::placeholder {
  color: #ced4da;
}

.search-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.assigned-users {
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow: auto;
}

.assigned-users::-webkit-scrollbar {
  width: 10px;
}
.assigned-users::-webkit-scrollbar-track {
  background: transparent;
}
.assigned-users::-webkit-scrollbar-thumb {
  background: #f2f5f8;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  transition: 0.2s;
}
.assigned-users::-webkit-scrollbar-thumb:hover {
  background: #e6e8eb;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
}
.assigned-users .member-row .pic {
  width: 40px;
  height: 40px;
}
.assigned-users .member-row .pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>