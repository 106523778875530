<template>
    <div class="create-btn" @click="create = true">
        <span>Create</span>
    </div>
    <form-listing :loaded="loaded" :entries="bodyItems" :headItems="headItems" :canCreate="true" :title="'Ticket Type'" @optionSel="handleOption"/>
    <Create v-if="create"
        :items="toCreate"
        :response="response"
        @close=";(create = false), (selItem = 0)" 
        @data="handleCreate"
    />
</template>

<script>
import FormListing from '@/components/List/Table/Head.vue'
import Create from "@/components/Create/Index.vue";
import axiosInstance from '@/services/AxiosTokenInstance';
import api from "@/mixins/optionsApi";

export default {
    props: {
        type: String
    },

    data() {
        return {
            loaded: false,
            entries: [],
            create: false,
            headItems: ['Typology', 'Parent'],
            bodyItems: [],
            editOptions: ["edit"],
            hasOptions: true,
            selItem: 0,
            edit: false,
            editInfo: {},
            response: [],
            parents: this.api("parent-types")
        }
    },

    mixins: [api],

    emits:['close'],

    computed: {
        toCreate() {
            return [
                {
                    type: "text",
                    name: "title",
                    label: "Title",
                    required: true,
                },
                {
                    type: "boolean",
                    name: "hasParent",
                    label: "Has a Parent?",
                    required: false,
                    extra: [
                        {
                            type: "select",
                            name: "parent",
                            label: "Parent",
                            options: this.parents,
                            required: true,
                        }
                    ],
                },
            ];
        },
    },

    components: {
        FormListing,
        Create
    },

    provide() {
        return {
            edit: this.editOptions,
            hasOptions: this.hasOptions,
        };
    },

    watch: {
        entries() {
            this.fillBodyItems();
        },
        type() {
            this.getData()
        },
        selItem(val) {
            if(!val) {
                this.editInfo = {}
                return
            }
        }
    },

    methods: {
        fillBodyItems() {
            console.log(this.entries)
            // let parents = this.entries.filter(el => !el.parent)
            // const childs = this.entries.filter(el => el.parent)
            
            // console.log(parents, childs)
            this.bodyItems = this.entries.map((item) => {
                return [
                    {
                        template: "normal",
                        id: item.id,
                        data: item.name || '',
                    },
                    {
                        template: "normal",
                        data: item.parent?.name || '-',
                    }
                ];
            });
        },
        async getData() {
            this.loaded = false

            const response = await axiosInstance.get(`typology`).finally(() => this.loaded = true)
            this.parents = await this.api("parent-types")
            if(response.data.data) this.entries = response.data.data.data
        },

        async handleCreate(data) {
            console.log(data)
            const orgData = JSON.parse(localStorage.getItem('organization'));

            const toSend = {
                name: data?.title,
                organization_id: orgData.id
            }

            if (data.hasParent && data.hasParent.hasParent) toSend.parent_id = data.hasParent.parent.id

            const response = await axiosInstance.post('typology', toSend)

            if (response.status === 200) {
                this.response = [true, true]
                this.getData()
            }
            else {
                this.response = [true, false]
            }
            console.log(response)
        },

        async deteleType() {
            await axiosInstance.delete(`typology/${this.selItem}`).finally(() => {
                this.getData()
            })
        },

        handleOption(data) {
            this.selItem = data[1];
            switch (data[0]) {
                case "edit":
                    this.edit = true
                break;
                case "delete":
                    this.deteleType();
                break;
            }
        },
    },

    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.create-btn {
    position: absolute;
    padding: 10px 25px;
    top: 10px;
    right: 20px;
    background: var(--primary-color);
    color: white;
    border-radius: 30px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;

    &:hover {
        background: var(--primary-hover);
    }
}
</style>