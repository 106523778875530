<template>
    <div class="user-concern-area" :class="{isAttach: chat.type === 'attachment' && chat.media && chat.media.length, me: isMine , call: chat.type === 'joinCall' || chat.type === 'endCall' || chat.type === 'rejectCall' }">
        <div class="attachment" v-if="chat.type === 'attachment' && chat.media && chat.media.length">
            <template v-for="(att, index) in chat.media" :key="index">
                <div v-if="index <= 3" :class="{image: att.extension === 'png' ||  att.extension === 'jpg' ||  att.extension === 'jpeg' || att.extension === 'webp' || att.extension === 'gif', doc: att.extension !== 'png' && att.extension !== 'webp' && att.extension !== 'gif' && att.extension !== 'jpg' && att.extension !== 'jpeg' && att.extension !== 'text' && att.extension !== 'joinCall', group: (att.extension === 'png' ||  att.extension === 'jpg' ||  att.extension === 'jpeg' ||  att.extension === 'gif') && chat.media.length > 1}">
                    <video v-if="att.extension === 'mp4'" :src="att.url" :autoplay="false" controls="" loop="" muted="" frameborder="0" >
                        Your browser does not support HTML videos.
                    </video>
                    <span v-else-if="att.extension !== 'png' && att.extension !== 'gif' && att.extension !== 'jpg' && att.extension !== 'jpeg' && att.extension !== 'webp' && att.extension !== 'text' && att.extension !== 'joinCall'" class="document" @click="openDoc(att.url)">
                        <img :src="`/assets/images/icons/${att.extension}.png`" alt="doc" /><span>{{ att.name }}</span>
                    </span>
                    <template v-else>
                        <img v-if="chat.media.length <= 4" :src="att.url" alt="picture" @click="handleGallery(chat.media, index)"/>
                        <template v-else>
                            <img v-if="index < 3" :src="att.url" alt="picture" @click="handleGallery(chat.media, index)"/>
                            <span v-else-if="index === 3" @click="handleGallery(chat.media, index)">+</span>
                        </template>
                    </template>
                </div>
            </template>

        </div>
        <div class="image-text" v-else-if="chat.type === 'attachment' && chat.text">{{chat.text}}</div>
        <span v-else-if="chat.type === 'text' && chat.text" ref="txt">
            {{chat.text}}
        </span>
        <template v-else-if="chat.type === 'joinCall'">
            <div class="joinbox">
                <div class="start-call">
                    <div class="icon">
                        <img src="/assets/images/call.svg" alt="call icon">
                    </div>
                    <span>{{chat.sentBy.name}} started a call</span>
                </div>
                <div class="join-call">
                    <span>
                        click <span class="here" @click="$emit('join')">here</span> to join the call
                    </span>
                </div>
            </div>
        </template>
        <template v-else-if="chat.type === 'endCall' || chat.type === 'rejectCall'">
            <div class="endbox">
                <div class="icon">
                    <img src="/assets/images/call_ended.svg" alt="call icon">
                </div>
                <span>{{chat.text}}</span>
            </div>
        </template>
        <gallery v-if="Object.keys(toGallery).length" :data="toGallery" @close="toGallery = {}"/>
    </div>
</template>

<script>
import Gallery from '@/components/Ui/Chat/Gallery.vue'
export default {
    props: {
        chat: {
            required: false,
            type: Object,
        },
        isMine: {
            required: true,
            type: Boolean,
            default: () => false
        }
    },

    data() {
        return {
            toGallery: {}
        }
    },

    components: {
        Gallery
    },

    mounted() {
        if(this.chat.type === 'text' && this.chat.mentionedUsers?.length) {
            
            let msg = this.chat.text

            this.chat.mentionedUsers.forEach(el => {
                msg = msg.replace(`@${el.name}`, `<mark>@${el.name}</mark>`)
            })

            this.$refs.txt.innerHTML = msg
        }
    },

    methods: {
        handleGallery(items, sel) {
            this.toGallery = {
                items,
                init: sel
            }
        },

        openDoc(doc) {
            window.open(doc)
        }
    },
}
</script>

<style lang="scss" scoped>
.user-concern-area {
    background: #F2F5F8;
    padding: 10px;
    max-width: 50%;
    border-radius: 5px;

    &.call{
        background: none;

        &.me {
            background: none;
            .joinbox {
                .start-call {
                    flex-direction: row-reverse;
                }
                .icon {
                    transform: scaleX(-1);
                }

                .join-call {
                    align-items: flex-end;
                }
            }
            .endbox {
                flex-direction: row-reverse;
                .icon {
                    transform: scaleX(-1);
                }
            }
        }

        .endbox {
            display: flex;
            align-items: center;
            gap: 10px;

            .icon {
                width: 15px;
                img {
                    width: 100%;
                }
            }
            hr {
                border: none;
                width: 250px;
                height: 2px;
                background: #F2F5F8;
            }
            span {
                display: flex;
                justify-content: center;
                color: #cad6e2;
            }
        }

        .joinbox {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .start-call {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            .join-call {
                width: 250px;
                background: var(--primary-color);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                overflow: hidden;
                span {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    color: white;
                    padding: 5px 10px;
                    font-size: 0.8rem;

                    .here {
                        cursor: pointer;
                        padding: 5px;
                        background: white;
                        color: var(--primary-color);
                        border-radius: 2px;
                        transition: 0.2s;

                        &:hover {
                            background: $grey;
                        }
                    }
                }
            }

            .icon {
                width: 15px;
                img {
                    width: 100%;
                }
            }
            hr {
                border: none;
                width: 250px;
                height: 2px;
                background: #F2F5F8;
            }
            span {
                display: flex;
                justify-content: center;
                color: #cad6e2;
            }
        }
    }

    &.isAttach {
        padding: 0;
        background: unset;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .attachment {
        display: flex;
        flex-wrap: wrap;
        max-width: 500px;

        .document {
            cursor: pointer;
            user-select: none;
        }

        .image {
            height: 150px;
            width: fit-content;
            max-width: 250px;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            border: solid 1px #F2F5F8;
            cursor: pointer;
            transition: 0.2s;
            &:hover {
                background: rgba(0,0,0,0.1);
            }
            &.group {
                flex: 1 0 50%;
                width: 62.5px;
                height: 75px;

                span {
                    color: var(--primary-color);
                    font-size: 2rem;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                img {
                    height: 90%;
                }
            }
            img {
                height: 100%;
                object-fit: contain;
            }
        }

        .doc {
            padding: 10px;
            background: #F2F5F8;
            max-width: 100%;
            a {
                text-decoration: none;
            }
            video {
                width: 100%;
            }
        }
    }

    .image-text{
        background: #F2F5F8;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 10px;
    }

    &.me {
        background: var(--primary-color);
        color: white;

        &.isAttach {
            background: unset;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .image-text{
            background: var(--primary-color);
        }

        .document {
            color: black;
        }
    }
}
</style>