<template>
  <div class="wrapper">
    <div class="video-wrapper" :class="{ 'w-video': session && !getDisabled }">
      <div class="innerVideo" id="videochat"></div>
    </div>
    <div class="whole" :class="{ 'w-video': session && !getDisabled }">
      <div class="chat-wrapper" ref="wrapper" @scroll="handleScroll">
        <details-info :ticket="productDetails" />
        <template v-if="chatLoaded">
          <template
            v-for="(chat, chat_index) in chatDetails.chatDetails"
            :key="chat_index"
          >
            <Messages :chat="chat" />
          </template>
          <template v-for="(chat, chat_index) in newMessages" :key="chat_index">
            <Messages :chat="chat" />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  CHAT_ROOM_USERS_LIST,
  GET_CHATS_LIST,
  UPLOAD_CHAT_ATTACHMENT,
} from "@/store/storeconstants";
import DetailsInfo from "@/components/Chat/Details.vue";
import Messages from "@/components/Chat/Messages.vue";
export default {
  props: {
    details: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      internalInstance: getCurrentInstance(),
      currentChatPage: 1,
      chatLoaded: false,
      chatDetails: {},
      newMessages: [],
      currentScroll: 0,
      showVid: false,
      user: localStorage.getItem("U_P")
        ? JSON.parse(localStorage.getItem("U_P"))
        : {},
      session: false,
      usersInTicket: [],
      imgURL: process.env.VUE_APP_DO_SPACES,
    };
  },

  components: {
    DetailsInfo,
    Messages,
  },

  computed: {
    productDetails() {
      return {
        product: {
          name: this.details.product?.name,
          serial_no: this.details.product?.serial_no,
          warranty_end_date: this.details.product?.warranty_end_date,
          client: {
            name: this.details.client?.name,
          },
          ref: this.details.product?.model,
        },
        project: this.details?.site,
        description: this.details?.description,
        image: this.details.product
          ? this.details.product.product_images.length
            ? this.imgURL + this.details.product.product_images[0].image
            : this.details.client.logo
            ? this.imgURL + this.details.client.logo
            : null
          : null,
      };
    },

    ...mapGetters({
      getCallStatus: "call/getCallStatus",
      getCallId: "call/getCallId",
      getDisabled: "call/getDisabled",
    }),
  },

  watch: {
    getCallId: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.session = false;
          setTimeout(() => {
            this.setDisabled(true);
          }, 50);
        }
        if (val && val === this.chatDetails.room) {
          this.session = true;
          setTimeout(() => {
            this.setDisabled(false);
          }, 50);
        } else {
          this.session = false;
          this.setDisabled(true);
        }
      },
    },
    details: {
      immediate: true,
      handler() {
        this.currentScroll = 0;
        this.chatLoaded = false;
        this.currentChatPage = 1;
        this.getChatData();
      },
    },

    currentChatPage() {
      this.getChatData();
    },
    chatDetails: {
      deep: true,
      handler(val) {
        this.readMessage();
        if (!this.getCallId) {
          this.session = false;
          setTimeout(() => {
            this.setDisabled(true);
          }, 50);
        } else if (this.getCallId && val.room === this.getCallId) {
          this.session = true;
          setTimeout(() => {
            this.setDisabled(false);
          }, 50);
        } else {
          this.session = false;
          this.setDisabled(true);
        }
      },
    },
  },

  methods: {
    ...mapActions("chat", {
      fetchChatList: GET_CHATS_LIST,
      updateChatFile: UPLOAD_CHAT_ATTACHMENT,
      fetchChatUsers: CHAT_ROOM_USERS_LIST,
    }),

    ...mapMutations({
      setDisabled: "call/setDisabled",
      setCallStatus: "call/setCallStatus",
      setCallId: "call/setCallId",
    }),

    async getChatData() {
      if (this.details.id) {
        let chatParams = {
          page_id: this.currentChatPage,
          ticket_id: this.details.id,
        };

        await this.fetchChatList(chatParams).then((response) => {
          this.totalChats = Math.ceil(response.data.totalChatCount / 10);
          this.chatLoaded = true;
          const wrapper = this.$refs.wrapper;

          if (response && this.currentChatPage === 1) {
            this.chatDetails = response.data;
            this.chatDetails.chatDetails.reverse();
            setTimeout(() => {
              wrapper.scrollTop = wrapper.scrollHeight;
            }, 200);
          } else if (response && this.currentChatPage !== 1) {
            let newChats = response.data.chatDetails.reverse();
            this.chatDetails.chatDetails.unshift(...newChats);
            setTimeout(() => {
              wrapper.scrollTop = wrapper.scrollHeight - this.currentScroll;
            }, 1);
          }
        });

        this.fillUsers();

        const userParams = {
          room_id: this.chatDetails.room,
        };

        await this.fetchChatUsers(userParams).then((data) => {
          const isInTicket = this.usersInTicket.includes(this.user.id);
          const isJoined = data.data.users.filter(
            (el) => el.userId === this.user.id
          );

          if (isInTicket && !isJoined.length) {
            this.joinRoom();
          }
        });
      }
    },

    handleScroll(e) {
      if (e.target.scrollTop === 0 && this.currentChatPage < this.totalChats) {
        this.currentScroll = e.target.scrollHeight;
        this.currentChatPage += 1;
      }
    },

    startCall() {
      let userToSend = this.user;
      userToSend.type = "single";
      const params = {
        roomId: this.chatDetails.room,
        with: userToSend,
      };
      this.setCallStatus(true);
      this.setCallId(this.chatDetails.room);
      this.emitter.emit("request-to-join-call", params);
      this.emitter.emit("video-section-prepared", params);
    },

    fillUsers() {
      this.usersInTicket = [];
      this.usersInTicket.push(this.details.ticket_raised.id);
      if (this.details.assigned_users.length) {
        const assignees = this.details.assigned_users.map((el) => el.id);
        this.usersInTicket.push(...assignees);
      }
    },

    joinRoom() {
      const params = {
        ticketId: this.details.id,
      };
      this.emitter.emit("join-room", params);
    },

    readMessage() {
      let unreadMsg = [];
      this.chatDetails.chatDetails.forEach((element) => {
        const receivers = element.receivers
          .filter((el) => !el.isRead)
          .map((el) => el.userId);
        if (receivers.length) {
          if (receivers.includes(this.user.id)) unreadMsg.push(element._id);
        }
      });
      const data = {
        room: this.chatDetails.room,
        chatIds: unreadMsg,
      };
      this.emitter.emit("read-message", data);
    },
  },

  created() {
    this.emitter.on("new-message", (data) => {
      console.log(data.room._id, this.chatDetails.room);
      if (data.room._id === this.chatDetails.room) {
        let newArr = [
          {
            id: data._id,
            attachmentUrls: data.attachmentUrls,
            createdAt: data.createdAt,
            media: data.media,
            receivers: data.receivers,
            room: data.room,
            sentBy: data.sentBy,
            status: data.status,
            text: data.text,
            type: data.type,
          },
        ];
        this.newMessages.push(...newArr);
        if (data.type === "rejectCall") this.emitter.emit("destroy-call");

        setTimeout(() => {
          const wrapper = this.$refs.wrapper;
          if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;
        }, 200);
      }
    });

    this.emitter.on("chats-deleted", (data) => {
      if (data.room === this.chatDetails.room) {
        this.newMessages = [];
        this.chatDetails.chatDetails = [];
        this.chatDetails.totalChatCount = 0;
      }
    });

    this.emitter.on("user-typing", (data) => {
      if (
        data.room === this.chatDetails.room &&
        data.userDetails.userId !== this.user.id
      ) {
        this.userIsTyping = true;
        const wrapper = this.$refs.wrapper;
        if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;

        clearTimeout(timer);
        const timer = setTimeout(() => {
          this.userIsTyping = false;
        }, 3000);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 20px;
  position: relative;
  height: 100%;
  display: flex;
  .video-wrapper {
    width: 0px;
    &.w-video {
      width: 50%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
    }
    .innerVideo {
      width: 100%;
      height: 100%;
    }
  }
  .whole {
    display: flex;
    flex-direction: column;

    &.w-video {
      width: 50%;
      padding: 0 0 0 20px;
      .chat-wrapper {
        .details-wrapper {
          width: 100%;
        }
      }
    }
    .chat-wrapper {
      height: 95%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0 20px 20px 0;
      scroll-behavior: smooth;
      transition: 0.2s;
      
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #f2f5f8;
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        transition: 0.2s;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #e6e8eb;
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
      }
    }
  }

  .chat-bar {
    height: 5%;
  }
}
</style>