<template>
    <main class="ticket-forms">
        <Info />
    </main>
</template>

<script>
import Info from './info/Index.vue'
export default {
    components: {
        Info
    },
}
</script>

<style lang="scss" scoped>
.ticket-forms {
    display: flex;
    gap: 3rem;
}
</style>