<template>
  <div class="item">
    <label>
      <span>{{ item.label }}</span>
      <span v-if="item.required"> *</span>
    </label>
    <div class="select" v-click-outside="handleOutside">
      <input
        v-if="filteredOptions.length || searchKey.length"
        class="chosen-value"
        type="text"
        v-model="searchKey"
        :placeholder="`Search for ${item.label.toLowerCase()}`"
        @click="down = true"
      />
      <transition name="slide-down" v-if="filteredOptions.length">
        <ul class="value-list" v-if="down && filteredOptions.length">
          <template v-for="(opt, n) in filteredOptions" :key="n">
            <li @click="handleSelect(opt)">
              <figure v-if="opt.image">
                <img :src="opt.image" alt="" />
              </figure>
              <figure v-else class="noImg">
                <span>{{ opt.name.charAt(0) }}</span>
              </figure>
              <span>{{ opt.name }}</span>
            </li>
          </template>
        </ul>
      </transition>
      <div
        class="sel"
        :class="{ w_search: filteredOptions.length }"
        v-if="selects.length"
      >
        <span class="title">Selected {{ item.label.toLowerCase() }}</span>
        <div class="all">
          <template v-for="(sel, x) in selects" :key="x">
            <div class="selItem" @click="removeItem(sel)">
              <div class="i">
                <figure v-if="sel.image">
                  <img :src="sel.image" alt="" />
                </figure>
                <figure v-else class="noImg">
                  <span>{{ sel.name.charAt(0) }}</span>
                </figure>
                <span>{{ sel.name }}</span>
              </div>
              <span class="rem">x</span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/mixins/optionsApi";
export default {
  props: {
    item: {
      type: Object,
    },
    filled: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      down: false,
      options: [],
      filteredOptions: [],
      searchKey: "",
      selects: this.filled[this.item.name] || [],
      newItems: [],
      deletedItems: [],
      imgURL: process.env.VUE_APP_DO_SPACES,
    };
  },

  mixins: [api],

  watch: {
    options(val) {
      this.filteredOptions = val;
    },
    searchKey(val) {
      if (val.length)
        this.filteredOptions = this.options.filter((el) =>
          el.name.toLowerCase().includes(val.toLowerCase())
        );
      else this.filteredOptions = this.options;
    },
    selects: {
      deep: true,
      handler() {
        const toSend = {
          new_items: this.newItems,
          deleted_items: this.deletedItems,
        };
        this.$emit("data", [this.item.name, toSend]);
      },
    },
  },

  methods: {
    async getData() {
      this.options = await this.api(`${this.item.api}`);
      if (this.filled[this.item.name]) {
        const all = this.options;
        this.options = all.filter(
          (el) => !this.selects.some((a) => a.id === el.id)
        );
      }
    },

    handleOutside() {
      this.down = false;
    },

    handleSelect(item) {
      this.newItems.push(item);
      this.selects.push(item);
      const index = this.options.map((el) => el.id).indexOf(item.id);
      this.options.splice(index, 1);

      const deleted_index = this.deletedItems
        .map((el) => el.id)
        .indexOf(item.id);
      if (deleted_index >= 0) this.deletedItems.splice(deleted_index, 1);

      if (!this.options.length) this.down = false;
    },

    removeItem(item) {
      const index = this.selects.map((el) => el.id).indexOf(item.id);
      this.options.push(item);
      this.deletedItems.push(item);
      this.selects.splice(index, 1);

      const new_index = this.newItems.map((el) => el.id).indexOf(item.id);
      if (new_index >= 0) this.newItems.splice(new_index, 1);
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.slide-down-enter-active {
  animation: slide-down 0.5s;
}
.slide-down-leave-active {
  animation: slide-down 0.5s reverse;
}
label {
  font-weight: 500;
  font-size: 1rem;
  color: var(--primary-color);
}

.select {
  width: 100%;
  display: flex;
  flex-direction: column;

  .sel,
  input {
    font-weight: 500;
    border-radius: 2px;
    user-select: none;
  }

  input {
    padding: 10px 15px;
    border-left: solid 1px #cad6e2;
  }

  .sel {
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &.w_search {
      margin-top: 5px;
    }

    .title {
      padding: 5px 0px;
      font-weight: 500;
      color: $grey;
    }

    .all {
      display: flex;
      flex-wrap: wrap;
      border-left: solid 1px var(--primary-color);
      .selItem {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.2s;
        padding: 10px 15px;
        border-bottom: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;

        &:first-of-type {
          border-top: 1px solid #eeeeee;
        }

        &:hover {
          background: $grey-hover;

          .rem {
            color: $red;
          }
        }
        .i {
          display: flex;
          align-items: center;
          gap: 10px;

          figure {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            &.noImg {
              background: var(--primary-color);
              color: white;
            }

            img {
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  ul {
    height: 100%;
    max-height: 200px;
    overflow: auto;
    border-left: solid 1px var(--primary-color);
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #f2f5f8;
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      transition: 0.2s;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #e6e8eb;
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
    }
    li {
      padding: 10px;
      user-select: none;
      cursor: pointer;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      align-items: center;
      gap: 10px;
      transition: 0.2s;

      &:last-of-type {
        border: none;
      }

      &:hover {
        background: $grey;
      }

      figure {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        &.noImg {
          background: var(--primary-color);
          color: white;
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    height: 0px;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    overflow: hidden;
  }
}
</style>