<template>
  <div class="contentTitle">
	<h3>History</h3>
  </div>
  <div class="top-search">
	<div class="title">What are you looking for?</div>
	<div class="filters">
		<div class="search">
			<div class="searchPan">
				<i class="fa fa-search searchIcon" aria-hidden="true"></i>
				<input
					type="text"
					class="form-control"
					v-model="search_key"
					placeholder="Search by ticket"
				/>
			</div>
		</div>
		<div class="filter-items">
			<filter-by :items="toFilter" @selectedItem="filterByItem" :left="true"/>
		</div>
	</div>
  </div>
  <section class="users-list">
	<div class="table-head">
		<div class="main-items">
			<span>Time</span>
			<span>Ticket</span>
			<span>Client</span>
			<span>Product</span>
			<span>Assigned To</span>
			<span>Priority</span>
		</div>
	</div>
	<template v-if="entries.length && loaded">
		<template v-for="(entry, index) in entries" :key="index">
			<ticket :ticket="entry" />
		</template>
	</template>
	<template v-else-if="!entries.length && search_key && loaded">
		<span class="empty">
			There are currently no tickets for your search.
		</span>
	</template>
	<template v-else-if="!entries.length && loaded">
		<span class="empty"> There are currently no tickets closed. </span>
	</template>
	<template v-else>
		<div class="loading">
			<span class="loader"></span>
		</div>
	</template>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { getCurrentInstance } from "vue";
import { mapMutations } from "vuex";
import axiosInstance from "@/services/AxiosTokenInstance";
import Ticket from "./Ticket.vue";
import FilterBy from '@/components/Ui/General/FilterBy.vue'
import api from "@/mixins/optionsApi";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";

export default {
  title() {
	return `Remote Assistance | ${this.title}`;
  },
  data: function () {
	return {
		timeout: null,
		create: false,
		entries: [],
		currentPage: 1,
		fullEntries: [],
		total: 0,
		pages: 0,
		perPage: 10,
		imgURL: process.env.VUE_APP_DO_SPACES,
		isChecked: false,
		search_key: "",
		toTitle: "Add Document",
		loaded: false,
		title: "History",
		internalInstance: getCurrentInstance(),
		client: localStorage.getItem("client")
			? JSON.parse(localStorage.getItem("client"))
			: null,
		filter: [],
        canCreate: [4, 5, 6, 7, 8],
	};
  },

  components: {
	Ticket,
	FilterBy
  },

  mixins: [api],

  watch: {
	search_key() {
		clearTimeout(this.timeout);

		this.timeout = setTimeout(() => {
			this.getData(1);
		}, 1000);
	},
	filter() {
		this.getData()
	}
  },
  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
	toFilter() {
        const toReturn = [
			{
				name: "product",
				options: this.api('products')
			},
			{
				name: "category",
				options: this.api('categories')
			},
			{
				name: "site",
				options: this.api('sites')
			},
			{
				name: "user",
				options: this.api('client_users')
			}
		]

        if(this.canCreate.includes(this.getUserProfile.role.id)) toReturn.push({
            name: "client",
            options: this.api("clients"),
        })

		return toReturn;
	},
	toCreate() {
		return [
			{
				type: "text",
				label: "Title",
			},
			{
				type: "texteditor",
				label: "Answer",
			},
		];
	},
  },
  methods: {
	...mapMutations({
		pathing: "pathing/setPathing",
	}),

	async getData(value) {
			this.loaded = false;
			this.currentPage = value;
			const client_id = this.client ? this.client.id : "";
			let orgDataString = localStorage.getItem("organization");
			if (orgDataString) {
			let orgData = JSON.parse(orgDataString);
			let response
			if (!this.filter.length) {
				response = await axiosInstance
					.get(
						`ticket-history?org_id=${orgData.id}&clientId=${client_id}&searchKey=${this.search_key}`
					)
					.finally(() => (this.loaded = true));
			}
            else if (this.filter[0] === 'clientId'){
                response = await axiosInstance
				.get(
					`ticket-history?org_id=${orgData.id}&clientId=${this.filter[1]}&searchKey=${this.search_key}`
				)
				.finally(() => (this.loaded = true));
            }
			else {
				response = await axiosInstance
				.get(
					`ticket-history?org_id=${orgData.id}&clientId=${client_id}&${this.filter[0]}=${this.filter[1]}searchKey=${this.search_key}`
				)
				.finally(() => (this.loaded = true));
			}
			
			let responseData = response.data.data;
			this.entries = responseData.data;
			this.perPage = responseData.per_page;
			this.total = responseData.total;
			this.pages = responseData.last_page;
		}
	},

	filterByItem(data) {
      switch (data[0]) {
        case "product":
          this.filter = ["productId", data[1]];
          break;
        case "category":
          this.filter = ["categoryId", data[1]];
          break;
        case "site":
          this.filter = ["siteId", data[1]];
          break;
        case "user":
          this.filter = ["userId", data[1]];
          break;
        case "client":
          this.filter = ["clientId", data[1]];
            break;
        case "remove":
          this.filter = [];
        break;
      }
    },
  },
  mounted() {
	this.currentPage = 1;
	this.getData(this.currentPage);
  },
};
</script>

<style lang="scss" scoped>
.top-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  padding: 40px 0;
  .title {
	font-size: 1.5rem;
	font-weight: bold;
  }
  .filters {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 100%;
		.search {
		width: 60%;
		display: flex;
		justify-content: center;
			.searchPan {
				position: relative;
				display: flex;
				align-items: center;

				.searchIcon {
				position: absolute;
				left: 10px;
				color: #cad6e2;
				}
				.form-control {
				padding: 10px 30px;
				&::placeholder {
					color: #cad6e2;
					font-size: 0.8rem;
					text-align: center;
				}
				}
			}
		}

		.filter-items {
			position: absolute;
			right: 0;
		}
  }
  
}

.users-list {
  height: 100%;
  max-height: 50vh;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  .table-head {
	position: sticky;
	top: 0;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 10px 25px;
	box-shadow: 0px 1px 6px #cad6e280;
	border-bottom: solid 1px #f2f5f8;
	background: white;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	z-index: 2;
	.main-items {
		width: 98%;
		display: flex;
		span {
		width: calc(93% / 6);
		display: flex;
		font-size: 0.8rem;
		font-weight: 500;

		&:first-of-type {
			width: 7%;
		}
		&:nth-child(2) {
			width: 30%;
		}
		}
	}
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  gap: 5px;
  margin-bottom: 10px;

  img {
	width: 8px;
	cursor: pointer;
  }

  .underline {
	text-decoration: underline;
  }
}

.empty {
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-weight: 500;
}

.loading {
  height: 500px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--primary-color);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
	transform: rotate(0deg);
  }
  100% {
	transform: rotate(360deg);
  }
}
</style>