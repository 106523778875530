<template>
    <div class="details-container">
        <main>
            <div class="title">{{details.title}}</div>
            <div class="info">
                <div class="item">
                    <div class="subtitle">Device</div>
                    <div class="text">{{details.product.name}}</div>
                </div>
                <div class="item">
                    <div class="subtitle">Serial</div>
                    <div class="text">{{details.product.serial_no}}</div>
                </div>
                <div class="item">
                    <div class="subtitle">Clients</div>
                    <div class="text" v-if="details.site && details.site.client">
                        <span>{{details.site.client.client_details?.name}}</span>
                    </div>
                    <div class="text" v-else>undefined</div>
                </div>
            </div>
        </main>
        <div class="description">
            <div class="subtitle">Description</div>
            <div class="text">{{details.description}}</div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    details: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style scoped>
.details-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.details-container main{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.details-container main .title{
    font-weight: bold;
    font-size: 1.3rem;
}

.details-container main .info
{
    display: flex;
    gap: 60px;
}
.details-container main .info .item{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.details-container .subtitle {
    font-weight: bold;
}

.details-container .description{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

</style>