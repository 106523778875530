<template>
    <section class="info">
        <header>
            <span class="title">Ticket Types</span>
        </header>
        <article>
            <Forms />
        </article>
    </section>
</template>

<script>
import Forms from './Forms/Index.vue'
export default {
    data() {
        return {
            orgInfo: localStorage.getItem("organization") ?  JSON.parse(localStorage.getItem("organization")) : {},
            save: false,
        }
    },

    components: {
        Forms,
    },
}
</script>

<style lang="scss" scoped>
.info {
    background: rgba(255,255,255,0.4);
    max-height: 80vh;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;

    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #f2f5f8;
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        transition: 0.2s;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #e6e8eb;
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
    }

    header {
        display: flex;
        justify-content: space-between;
        .title {
            text-transform: capitalize;
            font-weight: bold;
            font-size: 1.2rem;
        }
    }
}
</style>