<template>
  <div class="contentTitle">
    <span @click="goBack"
      ><h3 class="pointer">
        <i class="fa fa-angle-left" aria-hidden="true"></i> History
      </h3></span
    >
  </div>
  <div class="contentMenu"></div>
  <div class="ticket-wrapper inbox-message-wrapper">
    <div class="ticket-left">
      <div class="ticket-left-top"></div>
      <div class="ticket-listing">
        <span
          v-for="(tab, index) in tabs"
          :key="index"
          class="tab"
          :class="{ active: isActive === index }"
          @click="isActive = index"
          >{{ tab }}</span
        >
      </div>
    </div>
    <div class="ticket-right">
      <div class="ticket-left-top">
        <div class="user-details">
          <img src="/assets/images/profile.png" alt="" />
          <div v-if="ticketDetails" class="ticket-info">
            <span class="ticket-name"
              >{{ ticketDetails.title }} #{{ ticketDetails.serial_no }}</span
            >
            <span class="ticket-desc">
              {{
                ticketDetails.ticket_raised
                  ? ticketDetails.ticket_raised.name
                  : ""
              }}
              <span>
                |
                {{
                  ticketDetails.client_name ? ticketDetails.client_name : ""
                }}</span
              >
              <span class="date"> • created {{ timeSince }} </span>
            </span>
          </div>
        </div>
        <div class="right-corner">
          <div class="priorities">
            <div class="current" @click="openPriorities = true">
              <div class="prio">
                <img :src="priority.icon" alt="" />
                <span :class="priority.name">{{ priority.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="templates-container">
        <messages v-if="isActive === 0" :details="ticketDetails" />
        <assignees v-if="isActive === 1" :details="ticketDetails" />
        <product-details v-if="isActive === 2" :details="ticketDetails" />
        <Details v-if="isActive === 3" :details="ticketDetails" />
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
import { getCurrentInstance } from "vue";
import { mapActions } from "vuex";
import Assignees from "./Assign.vue";
import Messages from "./Messages.vue";
import Details from "./Details.vue";
import ProductDetails from "./ProductDetails.vue";
import {
  CHANGE_TICKET_PRIORITY_ACTION,
  GET_TICKET_DETAILS_ACTION,
} from "@/store/storeconstants";
export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Remote Support History",
      tabs: ["Messages", "Assignees", "Product Details", "Ticket Details"],
      isActive: 0,
      ticketId: "",
      priorityStatus: "",
      priorityFlag: undefined,
      ticketDetails: [],
      internalInstance: getCurrentInstance(),
      imgURL: process.env.VUE_APP_DO_SPACES,
      priorities: [
        {
          id: undefined,
          icon: "/assets/images/nan_flag.svg",
          name: "chose priority",
        },
        {
          id: 1,
          icon: "/assets/images/low_flag.svg",
          name: "low",
        },
        {
          id: 2,
          icon: "/assets/images/medium_flag.svg",
          name: "medium",
        },
        {
          id: 3,
          icon: "/assets/images/high_flag.svg",
          name: "high",
        },
      ],
      selectedPriority: {},
      priority: {},
      openPriorities: false,
      closePrios: false,
    };
  },
  components: {
    Messages,
    Details,
    ProductDetails,
    Assignees,
  },
  computed: {
    timeSince() {
      const last_updated = Date.parse(this.ticketDetails.updation_date);
      const seconds = Math.floor((new Date() - last_updated) / 1000);
      let interval = seconds / 31536000;
      if (interval > 1) {
        return `at ${this.ticketDetails.updation_date.split("T")[0]}`;
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return `at ${this.ticketDetails.updation_date.split("T")[0]}`;
      }
      interval = seconds / 86400;
      if (interval > 1) {
        if (Math.floor(interval) > 5)
          return `at ${this.ticketDetails.updation_date.split("T")[0]}`;
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "day ago" : "days ago"
        }`;
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "hour ago" : "hours ago"
        }`;
      }
      interval = seconds / 60;
      if (interval > 1) {
        return `${Math.floor(interval)} ${
          Math.floor(interval) === 1 ? "minute ago" : "minutes ago"
        }`;
      }
      return `${Math.floor(seconds)} ${
        Math.floor(seconds) === 1 ? "second ago" : "seconds ago"
      }`;
    },
  },
  methods: {
    ...mapActions("ticket", {
      fetchTicketDetails: GET_TICKET_DETAILS_ACTION,
      changeTicketPriority: CHANGE_TICKET_PRIORITY_ACTION,
    }),
    async getTicketDetails() {
      let orgDataString = localStorage.getItem("organization");
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        const response = await axiosInstance.get(
          `ticket/${this.ticketId}?org_id=${orgData.id}`
        );
        this.ticketDetails = response.data.data;

        let icon;
        let name;
        let id = this.ticketDetails.ticket_priority.priority_details.id;

        switch (this.ticketDetails.ticket_priority.priority_details.id) {
          case 1:
            icon = "/assets/images/low_flag.svg";
            name = "low";
            break;
          case 2:
            icon = "/assets/images/medium_flag.svg";
            name = "medium";
            break;
          case 3:
            icon = "/assets/images/high_flag.svg";
            name = "high";
            break;

          default:
            icon = "/assets/images/nan_flag.svg";
            name = "set priority";
            break;
        }

        this.priority = { id, icon, name };
      }
    },
    async handlePrio(prio) {
      if (this.priority.id !== prio.id && prio.id !== undefined) {
        this.selectedPriority = prio;
        this.openPriorities = false;
        this.closePrios = false;
        await this.changeTicketPriority({
          ticket_id: this.ticketDetails.ticket_id,
          status: prio.id,
        }).then(() => {
          this.getTicketDetails();
        });
      } else {
        this.closePrios = true;
        setTimeout(() => {
          (this.openPriorities = false), (this.closePrios = false);
        }, 25);
      }
    },

    async handleCloseTicket() {
      const data = {
        ticket_id: this.ticketDetails.ticket_id,
        status_id: 5,
      };
      const response = await axiosInstance.post(`change-ticket-status`, data);

      if (response) this.$router.push("/remote-support/tickets");
    },

    goBack() {
      this.$router.go(-1);
      //this.$router.push({path : `/remote-support/history`})
    },
  },
  created() {
    this.ticketId = this.$route.params.ticketid;
    this.getTicketDetails();
  },
};
</script>

<style lang="scss" scoped>
.ticket-wrapper.inbox-message-wrapper {
  height: 75vh;
}

.ticket-wrapper.inbox-message-wrapper .ticket-right {
  height: 100%;
}
.ticket-wrapper.inbox-message-wrapper .ticket-left-top {
  height: 8%;
  padding: 0 40px;
}
.ticket-wrapper.inbox-message-wrapper .templates-container {
  height: 92%;
  overflow: auto;
}
.ticket-listing {
  display: flex;
  flex-direction: column;
}
.ticket-listing .tab {
  padding: 30px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.1s;
}
.ticket-listing .tab.active {
  font-weight: 600;
  color: var(--primary-color);
  background: #f2f5f8;
}

.ticket-wrapper.inbox-message-wrapper .ticket-left-top {
  display: flex;
  justify-content: space-between;
}
.ticket-wrapper.inbox-message-wrapper .ticket-left-top .user-details {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ticket-wrapper.inbox-message-wrapper .ticket-left-top .user-details img {
  width: 40px;
  height: 40px;
}
.ticket-wrapper.inbox-message-wrapper
  .ticket-left-top
  .user-details
  .ticket-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ticket-wrapper.inbox-message-wrapper
  .ticket-left-top
  .user-details
  .ticket-info
  .ticket-desc {
  font-size: 0.8rem;
}
.ticket-wrapper.inbox-message-wrapper
  .ticket-left-top
  .user-details
  .ticket-info
  .ticket-desc
  .date {
  color: #cad6e2;
  font-weight: 500;
}

.ticket-left-top {
  .right-corner {
    display: flex;
    align-items: center;
    gap: 20px;
    .close-tckt {
      cursor: pointer;
      user-select: none;
      font-weight: 400;
      padding: 5px 20px;
      color: #ed0423;
      border-radius: 30px;
      border: solid 1px #ed0423;
      transition: 0.2s;
      &:hover {
        border: solid 1px transparent;
        background: #ed0423;
        color: white;
      }
    }
  }
}

.priorities {
  text-transform: capitalize;
  font-weight: 400;
  position: relative;
  display: flex;
  cursor: pointer;
}
.priorities .current {
  display: flex;
  gap: 5px;
}
.priorities .current .prio {
  display: flex;
  gap: 5px;
}
.priorities .current .arrow {
  width: 10px;
  transform: rotate(270deg);
}

.priorities span {
  color: #cad6e2;
  font-size: 0.8rem;
}
.priorities .medium {
  color: #fb9a4b;
}
.priorities .high {
  color: #ed0423;
}

.priorities .toChoose {
  position: absolute;
  right: 0;
  width: 150px;
  box-shadow: 0px 1px 6px #cad6e280;
  border-radius: 5px;
  overflow: hidden;
  z-index: 3;
  background: white;
}
.priorities .toChoose.openPriority {
  animation: open 0.2s linear forwards;
}
.priorities .toChoose.closePriority {
  animation: close 0.2s linear forwards;
}
.priorities .toChoose .priority-option {
  height: 2rem;
  padding: 0 10px;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #cad6e280;
  user-select: none;
}
.priorities .toChoose .priority-option .arrow {
  width: 10px;
  transform: rotate(90deg);
}
.priorities .toChoose .priority-option:last-of-type {
  border: none;
}
.priorities .toChoose .priority-option:hover {
  background: #cad6e280;
}

@keyframes open {
  from {
    max-height: 0px;
  }
  to {
    max-height: 250px;
  }
}
@keyframes close {
  from {
    max-height: 250px;
  }
  to {
    max-height: 0px;
  }
}
</style>