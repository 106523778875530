<template>
    <div class="gal-overlay">
        <span class="close" @click="$emit('close')">X</span>
        <div class="gal-container">
            <section class="gal-main">
                <div class="img">
                    <img :src="images[isActive].url" alt="">
                </div>
            </section>
            <section class="gal-slider" @click="handleScroll">
                <span class="arrow left">
                    <img src="/assets/images/left-arrow.svg" alt="close_chevron">
                </span>
                <span class="arrow right">
                    <img src="/assets/images/right-arrow.svg" alt="open_chevron">
                </span>
                <div class="items" ref="scroll" >
                    <div class="item" v-for="(img, index) in images" :key="index" :class="{active: isActive === index}" @click="isActive = index">
                        <img :src="img.url" alt="">
                        <span class="unclickable"></span>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            isActive: this.data.init || 0,
            images: this.data.items
        }
    },

    methods: {
        handleScroll(e) {
            const init = (window.screen.width / 2) + (window.screen.width / 8)
            const back = (window.screen.width / 3)
            if(e.clientX > init) this.$refs.scroll.scrollLeft += 400
            if(e.clientX < back) this.$refs.scroll.scrollLeft -= 400
        }
    },

    mounted() {
        window.addEventListener('keyup', (e) => {
            if(e.keyCode === 27) this.$emit('close')
        })
    }
}
</script>

<style lang="scss" scoped>
.gal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 2;
    .close {
        position: absolute;
        top: 50px;
        right: 50px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: solid 1px white;
        user-select: none;
        cursor: pointer;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .gal-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .gal-main {
            height: 80%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            .img {
                max-width: 80%;
                max-height: 90%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                }
            }
        }
        .gal-slider {
            height: 20%;
            width: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            gap: 50px;
            max-width: 1200px;
            .arrow {
                position: absolute;
                width: 50px;
                height: 50px;
                cursor: pointer;
                img {
                    filter: invert(1);
                }
                &.left {
                    left: 0;
                }
                &.right {
                    right: 0;
                }
            }
            .items {
                width: 100%;
                display: flex;
                overflow: auto;
                scroll-behavior: smooth;
                gap: 50px;
                max-width: 1000px;
                &::-webkit-scrollbar {
                    height: 0px;
                }
                .item {
                    margin: auto;
                    width: 177px;
                    min-width: 177px;
                    height: 100px;
                    background: rgba(0,0,0,0.3);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    overflow: hidden;
                    user-select: none;
                    position: relative;
                    border: solid 1px transparent;

                    &.active {
                        border: solid 1px var(--primary-color);
                    }

                    img {
                        width: 100%;
                        object-fit: contain;
                    }

                    .unclickable {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

        }
    }
}
</style>