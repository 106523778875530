<template>
  <div class="video" id="video-chat-col" v-if="session"></div>
  <div
    class="chat"
    :class="{
      me: user.id === chat.sentBy.userId,
      call:
        chat.type === 'joinCall' ||
        chat.type === 'endCall' ||
        chat.type === 'rejectCall',
    }"
  >
    <div
      class="image"
      v-if="
        chat.type !== 'joinCall' &&
        chat.type !== 'endCall' &&
        chat.type !== 'rejectCall'
      "
    >
      <figure>
        <img
          v-if="chat.sentBy.name === 'Bot'"
          src="/assets/images/bot_icon.png"
          alt="Bot Icon"
        />
        <img
          v-else-if="chat.sentBy.avatar"
          :src="imgURL + chat.sentBy.avatar"
          alt="User Icon"
        />
        <span v-else class="no-img" :class="{me: user.id === chat.sentBy.userId}">{{chat.sentBy.name.charAt(0)}}</span>
      </figure>
    </div>
    <div class="message-wrapper">
      <div
        class="info"
        v-if="
          user.id !== chat.sentBy.userId &&
          chat.type !== 'joinCall' &&
          chat.type !== 'endCall' &&
          chat.type !== 'rejectCall'
        "
      >
        {{ chat.sentBy.name }}
      </div>
      <div class="message">
        <types :chat="chat" :isMine="user.id === chat.sentBy.userId" @join="handleRejoin" />
      </div>
      <div
        class="info"
        v-if="
          chat.type !== 'joinCall' &&
          chat.type !== 'endCall' &&
          chat.type !== 'rejectCall'
        "
      >
        {{ date }}
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import Types from "./Type.vue";
export default {
  props: {
    chat: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      internalInstance: getCurrentInstance(),
      imgURL: process.env.VUE_APP_DO_SPACES,
      user: localStorage.getItem("U_P")
        ? JSON.parse(localStorage.getItem("U_P"))
        : {},
      session: false,
    };
  },

  emits: ['joinCall'],

  components: {
    Types,
  },

  computed: {
    date() {
      if (
        this.internalInstance.appContext.config.globalProperties
          .$moment(this.chat.createdAt)
          .format("MMMM D, YYYY") ===
        this.internalInstance.appContext.config.globalProperties
          .$moment(Date.now())
          .format("MMMM D, YYYY")
      ) {
        return this.internalInstance.appContext.config.globalProperties
          .$moment(this.chat.createdAt)
          .format("h:mm a");
      }
      return this.internalInstance.appContext.config.globalProperties
        .$moment(this.chat.createdAt)
        .format("MMMM D, YYYY h:mm a");
    },
  },

  methods: {
      handleRejoin() {
          this.$emit('joinCall')
      }
  }
};
</script>

<style lang="scss" scoped>
.chat {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  .image {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: 0 0 5px 0;
    figure {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      user-select: none;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .no-img {
        width: 100%;
        height: 100%;
        border: solid 1px transparent;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 600;
        background: var(--primary-color);

        &.me { 
          border: solid 1px var(--primary-color);
          color: var(--primary-color);
          background: white;
        }
      }
    }
  }
  .message-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: calc(100% - 40px);
    .info {
      padding: 0 10px;
      font-weight: 500;
      font-size: 0.7rem;
    }
    .message {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }

  &.me {
    flex-direction: row-reverse;
    .message-wrapper {
      align-items: flex-end;
      .message {
        justify-content: flex-end;
      }
    }
  }

  &.call {
    width: 100%;
  }
}
</style>